<template>
  <div class="title-separation">
    <div
      class="col-12 relative"
      v-if="notificationConfigurations && templateKeywords"
    >
      <span
        v-if="isUnsaved()"
        class="fixed flex"
        style="right: 3em; gap: 0.5em; top: 10em; z-index: 10"
      >
        <Button label="Cancelar" @click="cancel()" icon="pi pi-times" />
        <Button label="Guardar" @click="save()" icon="pi pi-check" />
      </span>
      <h4>{{ title }}</h4>
      <div
        v-if="
          notificationConfigurations[bookingKey].EMAIL.configKey.type ===
          bookingKey
        "
      >
        <h5 class="subtitle-separation">Email</h5>
        <Editor
          id="editor_email"
          v-model="
            this.notificationConfigurations[this.bookingKey].EMAIL.template
          "
          :formats="editorFormats"
          editorStyle="min-height: 160px; max-height: 400px; height: 100%; overflow-y: scroll"
          @text-change="textEditorChange($event, bookingKey)"
        />
        <template-keyword-selector
          :keywords="templateKeywords[bookingKey]"
          @onKeywordSelect="addKeyword($event, bookingKey, 'EMAIL')"
          class="add-dynamic-data-button"
        />
      </div>
      <div
        v-if="
          notificationConfigurations[bookingKey].SMS.configKey.type ===
          bookingKey
        "
      >
        <h5 class="subtitle-separation">SMS</h5>
        <span
          >{{
            max.SMS - notificationConfigurations[bookingKey].SMS.template.length
          }}
          / {{ max.SMS }}</span
        >
        <Textarea
          id="editor_sms"
          placeholder="Tu mensaje"
          :autoResize="true"
          v-model="notificationConfigurations[bookingKey].SMS.template"
          @change="checkLength(bookingKey, 'SMS')"
          rows="3"
          cols="30"
          style="width: 100%"
        />
        <template-keyword-selector
          :keywords="templateKeywords[bookingKey].filter(keyword => keyword.keyword !== '{booking_details}')"
          @onKeywordSelect="addKeyword($event, bookingKey, 'SMS')"
          class="add-dynamic-data-button"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationService from "../../service/OrganizationService";
import * as _ from "lodash";
export default {
  props: ["title", "bookingKey"],
  created() {
    this.organizationService = new OrganizationService();
  },
  mounted() {
    const fetchData = async () => {
      const organizationPromise = this.organizationService.getOrganization(
        this.$route.params.id
      );
      const keywordsPromise = this.organizationService.getTemplateKeywords();

      organizationPromise.then((data) => {
        this.parseNotificationsConfig(data);
      });
      keywordsPromise.then((data) => {
        const parsedKeywords = {};
        data.forEach(
          (templateKeyword) =>
            (parsedKeywords[templateKeyword.notificationType] =
              templateKeyword.keywords)
        );
        this.templateKeywords = parsedKeywords;
      });

      // wait for all data to be fetched so editors are rendered
      await Promise.all([organizationPromise, keywordsPromise]);

      const editorEl = document
        .getElementById(`editor_email`)
        .querySelector(".ql-editor");
      editorEl.append("\n");
      // We need to wait for the event to trigger to remove the text
      setTimeout(() => editorEl.removeChild(editorEl.lastChild), 1);

      this.notificationConfigurations = _.cloneDeep(
        this.originalNotificationConfig
      );

      window.scrollTo(0, 0);
    };
    fetchData();
  },
  data() {
    return {
      organizationService: null,
      templateKeywords: null,
      notificationConfigurations: null,
      originalNotificationConfig: null,
      editorFormats: [
        "bold",
        "background",
        "color",
        "font",
        "code",
        "italic",
        "link",
        "size",
        "strike",
        "underline",
        "header",
        "indent",
        "list",
        "align",
        "direction",
        "code-block",
      ],
      max: {
        SMS: 150,
      },
      saving: false,
      editorInstance: null,
    };
  },
  methods: {
    parseNotificationsConfig(organization) {
      const notificationConfig = organization.config.notificationConfig;
      const templateConfig = {};
      notificationConfig.forEach((notificationConfig) => {
        if (templateConfig[notificationConfig.configKey.type] === undefined)
          templateConfig[notificationConfig.configKey.type] = {};
        templateConfig[notificationConfig.configKey.type][
          notificationConfig.configKey.method
        ] = notificationConfig;
        templateConfig[notificationConfig.configKey.type][
          notificationConfig.configKey.method
        ].template = notificationConfig.template.body;
      });
      this.originalNotificationConfig = templateConfig;
      this.notificationConfigurations = _.cloneDeep(templateConfig);
    },
    addKeyword(templateKeyword, type, method) {
      const keyword = templateKeyword.keyword;
      if (method === "EMAIL") {
        const quillInstance = this.editorInstance;
        const cursorIndex = quillInstance.getSelection(true);
        quillInstance.insertText(cursorIndex.index, keyword);

        const editorEl = document
          .getElementById(`editor_email`)
          .querySelector(".ql-editor");
        editorEl.append("\n");
        // We need to wait for the event to trigger to remove the text
        setTimeout(() => editorEl.removeChild(editorEl.lastChild), 1);
      } else {
        const textAreaSms = document.getElementById("editor_sms");
        const currentPosition = textAreaSms.selectionStart;
        this.notificationConfigurations[type][method].template = this.insertOnIndex(this.notificationConfigurations[type][method].template, currentPosition, keyword);
      }
    },
    insertOnIndex(originalString,index, valueToPut) {
      return originalString.substring(0,index) + valueToPut + originalString.substring(index)
    },
    textEditorChange($event, type) {
      const method = "EMAIL";
      if (!this.editorInstance) {
        if (!this.editorInstance) this.editorInstance = null;
        this.editorInstance = $event.instance;
        return;
      }
      const html = $event.htmlValue;
      this.notificationConfigurations[type][method].template = html;
    },
    async save() {
      this.saving = true;
      try {
        const newOrganization =
          await this.organizationService.putNotificationsConfig(
            this.$route.params.id,
            Object.values(this.notificationConfigurations).flatMap(
              (notificationPair) => [
                notificationPair.EMAIL,
                notificationPair.SMS,
              ]
            )
          );
        this.parseNotificationsConfig(newOrganization);
        this.saving = false;
        this.$toast.add({
          severity: "success",
          summary: "Configuración guardada",
          life: 3000,
        });
      } catch (error) {
        console.error(error);
        this.saving = false;
        this.$toast.add({
          severity: "error",
          summary: "Error guardando la configuración",
          detail: `Ha habido un error guardando la configuración, por favor inténtalo más tarde`,
          life: 3000,
        });
      }
    },
    cancel() {
      this.notificationConfigurations = _.cloneDeep(
        this.originalNotificationConfig
      );
    },
    checkLength(type, method) {
      if (
        this.notificationConfigurations[type][method].template.length >
        this.max[method]
      ) {
        this.notificationConfigurations[type][method].template =
          this.notificationConfigurations[type][method].template.substring(
            0,
            this.max[method]
          );
      }
    },
    isUnsaved() {
      return (
        JSON.stringify(this.notificationConfigurations) !==
        JSON.stringify(this.originalNotificationConfig)
      );
    },
  },
};
</script>

<style>
.title-separation {
  margin-top: 1.5rem;
}

.subtitle-separation {
  padding-top: 1rem;
}

.add-dynamic-data-button {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
