<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="containerClass">
          <OrganizationTopBar />
        </div>
      </div>
      <div class="card">
        <TabMenu
          :model="nestedRouteItems"
          v-model:activeIndex="notificationIndex"
          :readonly="false"
        />
        <NotificationConfig
          :title="nestedRouteItems[notificationIndex].label"
          :bookingKey="nestedRouteItems[notificationIndex].key"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationTopBar from '../OrganizationTopbar.vue';
import NotificationConfig from './NotificationConfig.vue';
import { NOTIFICATION_LABEL } from '../../constants/constants';

export default {
  data() {
    return {
      notificationIndex: NOTIFICATION_LABEL.findIndex(
        ({ key }) => key === this.$route.params.type
      ),
      nestedRouteItems: NOTIFICATION_LABEL,
    };
  },
  computed: {},
  watch: {
    notificationIndex() {
      let currentKey = NOTIFICATION_LABEL[this.notificationIndex].key;
      let baseRoute = `/#/organization/${this.$route.params.id}/notification/${currentKey}`;
      window.history.pushState({}, null, baseRoute);
    },
  },
  components: {
    OrganizationTopBar: OrganizationTopBar,
    NotificationConfig: NotificationConfig,
  },
};
</script>
